<template>
  <div>
    <b-form @submit.stop.prevent="formOnsubmit">
      <!-- Name input -->
      <b-form-group id="input-group-name" label="Nama:" label-for="input-name">
        <b-form-input id="input-name" v-model="form.name" placeholder="Nama"></b-form-input>
        <small class="text-danger">{{ error.name }}</small>
      </b-form-group>

      <b-form-group id="input-group-show_name" label="Nama Alias:" label-for="input-show_name">
        <b-form-input id="input-show_name" v-model="form.show_name" placeholder="Nama Alias"></b-form-input>
        <small class="text-danger">{{ error.show_name }}</small>
      </b-form-group>

      <!-- Description input -->
      <b-form-group id="input-group-description">
        <label for="input-description">Deskripsi: <em class="text-muted">opsional</em></label>
        <b-form-textarea id="input-description" v-model="form.description" placeholder="Deksripsi Field" rows="4"
          max-rows="8"></b-form-textarea>
        <small class="text-danger">{{ error.description }}</small>
      </b-form-group>

      <fieldset style="border: 1px solid #dee2e6; padding: 8px" class="mb-3">
        <legend style="
              border: 1px solid #dee2e6;
              padding: 9px;
              margin-left: 13px;
              font-size: initial;
              width: initial;
              background: white;
            ">
          Nilai Normal
          <!-- <em class="text-muted" style="font-size: 11px">Laki-laki</em> -->
        </legend>
        <div class="row mt-3">
          <div class="col-md-12">
            <label for="input-description">Laki-laki: </label>
            <b-input-group>
              <b-form-input v-model="form.start_range_male" type="text" placeholder="Awal"
                autocomplete="off"></b-form-input>
              <b-button squared variant="light">s/d</b-button>
              <b-form-input v-model="form.end_range_male" type="text" placeholder="Akhir"
                autocomplete="off"></b-form-input>
            </b-input-group>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-6">
                <small class="text-danger col-6">{{
                  error.start_range_male
                }}</small>
              </div>
              <div class="col-6">
                <small class="text-danger col-6">{{
                  error.end_range_male
                }}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <label for="input-description">Perempuan: </label>
            <b-input-group>
              <b-form-input v-model="form.start_range_female" type="text" placeholder="Awal"
                autocomplete="off"></b-form-input>
              <b-button squared variant="light">s/d</b-button>
              <b-form-input v-model="form.end_range_female" type="text" placeholder="Akhir"
                autocomplete="off"></b-form-input>
            </b-input-group>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-6">
                <small class="text-danger col-6">{{
                  error.start_range_female
                }}</small>
              </div>
              <div class="col-6">
                <small class="text-danger col-6">{{
                  error.end_range_female
                }}</small>
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <b-form-group id="input-group-uom" label="Satuan:" label-for="input-uom">
        <b-form-input id="input-uom" v-model="form.uom" placeholder="Satuan Nilai Normal"></b-form-input>
        <small class="text-danger">{{ error.uom }}</small>
      </b-form-group>

      <!-- Submit & Cancel button -->
      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button type="button" class="ml-2" variant="default" @click="$router.push('/masters/fields')">
        Batal
      </b-button>
    </b-form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    form: Object,
    route: String,
    purpose: {
      type: String,
      default: "add",
    },
  },

  data() {
    return {
      // Error
      error: {
        name: "",
        show_name: "",
        start_range_male: "",
        end_range_male: "",
        start_range_female: "",
        end_range_female: "",
        description: "",
        uom: "",
      },
    };
  },

  methods: {
    validationCheck() {
      for (const [key, value] of Object.entries(this.error)) {
        if(this.error[key] != 'end_range_male' && this.error[key] != 'end_range_female'){
          this.error[key] = ''
        }
      }

      let status = true

      if (this.form.end_range_male < this.form.start_range_male) {
        status = false
        this.error.end_range_male = "Kolom batas akhir nilai normal harus lebih besar dari batas awal"
      } else {
        this.error.end_range_male = ""
      }

      if (this.form.end_range_female < this.form.start_range_female) {
        status = false
        this.error.end_range_female = "Kolom batas akhir nilai normal harus lebih besar dari batas awal"
      } else {
        this.error.end_range_female = ""
      }

      return status
    },
    async formOnsubmit() {
      if (this.validationCheck()) {
        // Make Request
        let response = await module.submit(this.form, this.route);
        // Check Response
        if (response.state == "error") {
          // Validation Error
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
        } else {
          // Success
          Swal.fire(response.success.title, response.success.message, "success");
          this.$router.push("/masters/fields");
        }
      } else {
        Swal.fire({
          title: 'Gagal',
          text: 'Harap periksa kembali form',
          icon: 'error',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        })
      }
    },
  },
};
</script>

<style></style>