<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Tambah Parameter Pemeriksaan Klinik'">
          <template v-slot:body>
            <Form
              :form="form"
              :route="'fields'"
            />
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue";
import Form from '@/component/masters/fields/Form.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {

  data() {
    return {
      form: {},
    };
  },

  components: {
    Card,
    Form
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Parameter Pemeriksaan Klinik", route: "" },
      { title: "Daftar Parameter Pemeriksaan Klinik", route: "/masters/fields/list" },
      { title: "Tambah" },
    ])
  },

}

</script>